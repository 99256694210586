import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "ตลาดรถไฟ"
    }}>{`ตลาดรถไฟ`}</h3>
    <p>{`ตัวเกมในเวอร์ชั่นปัจจุบัน รถไฟภายในฟาร์มของผู้เล่น จะทำการวิ่งตามปกติจากที่หยุดพักมานาน ทำให้ผู้เล่นสามารถขายผลผลิตพิเศษได้ภายในเวลาที่รถไฟมาจอดอยู่ที่ฟาร์มเท่านั้น `}</p>
    <p><img alt="mat" src={require("./public/images/train.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      